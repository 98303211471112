export const TABLE_HEADERS_LIST_1 = [
  // {
  //   text: "GP Date",
  //   value: "gpDate",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 100,
  //   sortable: true,
  // },
  {
    text: "Item",
    value: "itemName",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 50,
  },
  {
    text: "Mark",
    value: "markName",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
  {
    text: "Grade",
    value: "gradeName",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    text: "Inv No",
    value: "invNo",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    text: "Rate",
    value: "purchasedRate",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 80,
    sortable: true,
  },
  // {
  //   text: "Purchase Type",
  //   value: "purchaseType",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 150,
  //   sortable: true,
  // },
  {
    text: "Net",
    value: "netKgs",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 80,
    sortable: true,
  },
  {
    text: "Density",
    value: "density",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 50,
    sortable: true,
  },
  // {
  //   text: "Stock Type",
  //   value: "inventoryId",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 150,
  //   sortable: true,
  // },
  {
    text: "Stock",
    value: "totalNetKgs",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    text: "Allocated",
    value: "allocatedNetKgs",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    text: "Location",
    value: "companyWarehouseName",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
  {
    text: "Bay",
    value: "bay",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  // {
  //   text: "Required Quantity",
  //   value: "qtyAction",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 150,
  //   sortable: true,
  // },
  // {
  //   text: "Selected No of Packages",
  //   value: "requiredPackages",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 150,
  //   sortable: true,
  // },
  {
    text: "Batch No",
    value: "batchNo",
    class: "text-secondary opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
];

export const TABLE_HEADERS_LIST_2 = [
  // {
  //   text: "GP Date",
  //   value: "gpDate",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 100,
  //   sortable: true,
  // },
  {
    text: "Item",
    value: "itemName",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 80,
    sortable: true,
  },
  {
    text: "Mark",
    value: "markName",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: true,
  },
  {
    text: "Grade",
    value: "gradeName",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    text: "Inv No",
    value: "invNo",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    text: "Rate",
    value: "purchasedRate",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 80,
    sortable: true,
  },
  // {
  //   text: "Purchase Type",
  //   value: "purchaseType",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 150,
  //   sortable: true,
  // },
  {
    text: "Net",
    value: "netKgs",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 80,
    sortable: true,
  },
  {
    text: "Density",
    value: "density",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 80,
    sortable: true,
  },
  // {
  //   text: "Stock Type",
  //   value: "inventoryId",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 150,
  //   sortable: true,
  // },
  {
    text: "Stock",
    value: "totalNetKgs",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    text: "Allocated",
    value: "allocatedNetKgs",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: false,
  },
  // {
  //   text: "Warehouse",
  //   value: "companyWarehouseName",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 150,
  //   sortable: true,
  // },
  {
    text: "Bay",
    value: "bay",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 100,
    sortable: true,
  },
  {
    text: "Selected No of Packages",
    value: "requiredPackages",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: false,
  },
  {
    text: "Required Quantity",
    value: "qtyAction",
    class: "text-secondary font-weight-bolder opacity-7",
    align: "center",
    width: 150,
    sortable: false,
  },

  // {
  //   text: "Batch No",
  //   value: "batchNo",
  //   class: "text-secondary font-weight-bolder opacity-7",
  //   align: "center",
  //   width: 250,
  //   sortable: true,
  // },
];

export const TABLE_HEADERS_AG_LIST_1 = [
  {
    headerName: "Item",
    field: "itemName",
    width: 120,
    headerCheckboxSelection: true,
    checkboxSelection: true,
  },
  {
    headerName: "Mark",
    field: "markName",
    width: 150,
  },
  {
    headerName: "Grade",
    field: "gradeName",
    width: 150,
  },
  {
    headerName: "Inv No",
    field: "invNo",
    width: 100,
  },
  {
    headerName: "Rate",
    field: "purchasedRate",
    width: 80,
  },

  {
    headerName: "Net",
    field: "netKgs",
    width: 80,
  },
  {
    headerName: "Density",
    field: "density",
    width: 100,
  },

  {
    headerName: "Stock",
    field: "totalNetKgs",
    width: 120,
  },
  {
    headerName: "Bags",
    field: "noOfPackages",
    width: 100,
  },
  {
    headerName: "Allocated",
    field: "allocatedNetKgs",
    width: 120,
  },
  {
    headerName: "Location",
    field: "companyWarehouseName",
    width: 150,
  },
  {
    headerName: "Bay",
    field: "bay",
    width: 80,
  },
  {
    headerName: "Batch No",
    field: "batchNo",
    width: 150,
  },
];

export const TABLE_HEADERS_AG_LIST_2 = [
  {
    headerName: "Item",
    field: "itemName",
    width: 120,
  },
  {
    headerName: "Mark",
    field: "markName",

    width: 150,
  },
  {
    headerName: "Grade",
    field: "gradeName",

    width: 200,
  },
  {
    headerName: "Inv No",
    field: "invNo",

    width: 100,
  },
  {
    headerName: "Rate",
    field: "purchasedRate",

    width: 100,
  },

  {
    headerName: "Net",
    field: "netKgs",

    width: 100,
  },
  {
    headerName: "Density",
    field: "density",

    width: 60,
  },

  {
    headerName: "Stock",
    field: "totalNetKgs",

    width: 120,
  },
  {
    headerName: "Bags",
    field: "noOfPackages",
    width: 100,
  },
  {
    headerName: "Allocated",
    field: "allocatedNetKgs",

    width: 100,
  },

  {
    headerName: "Bay",
    field: "bay",

    width: 100,
  },
];
