<template>
  <div>
    <v-container fluid class="px-6 py-3">
      <v-row>
        <!-- #region Card section 1 -->
        <v-col cols="12" :class="{ 'mb-5': currentPage.permissions.length <= 0 }">
          <v-card class="card-shadow border-radius-xl">
            <v-card-text class="px-0 py-0">
              <v-row class="pa-4 ms-3">
                <!-- #region Plan Name -->
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Provision Plan</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ this.blendPlan.name }}</label>
                  </v-row>
                </v-col>
                <!-- #endregion -->
                <!-- #region Production Date -->
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Production Date</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ formatDate(this.blendPlan.productionDate) }}</label>
                  </v-row>
                </v-col>
                <!-- #endregion -->
                <!-- #region Production Quantity -->
                <v-col cols="2">
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Quantity</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ this.blendPlan.productionQuantity }}</label>
                  </v-row>
                </v-col>
                <!-- #endregion -->
                <!-- #region Blend Code Name -->
                <v-col>
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Blend Code Name</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ this.blendPlan.blendCodeName }}</label>
                  </v-row>
                </v-col>
                <v-col>
                  <action-button :actions="currentPage.actions_allowed" @onClick="onActionClick" />
                </v-col>
                <v-col class="d-flex align-center shrink">
                  <v-btn @click="downloadPlan()" dark class="font-weight-bold text-capitalize btn-primary bg-success py-5 px-5 shadow">
                    <v-icon size="20" class="text-white mr-1"> fas fa-cloud-download-alt </v-icon>
                    Download
                  </v-btn>
                </v-col>
                <!-- #endregion -->
              </v-row>
              <v-row class="pa-4 ms-3">
                <v-col>
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Source Number</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{ this.blendPlan.sourceNumber }}</label>
                  </v-row>
                </v-col>
                <v-col>
                  <v-row class="tittle__row">
                    <label class="text-md text-typo ms-1">Status</label>
                  </v-row>
                  <v-row>
                    <label class="text-lg text-typo ms-1 label__value">{{
                      this.blendPlan.approvalStatus ? "Approved" : "Cancelled"
                    }}</label>
                  </v-row>
                </v-col>
              </v-row>
            </v-card-text>
          </v-card>
        </v-col>
        <!-- #endregion -->

        <!-- #region Table Section -->
        <v-col cols="12">
          <v-card class="card-shadow border-radius-xl">
            <v-data-table
              :headers="headers"
              :items="blendPlanDetails"
              item-key="id"
              mobile-breakpoint="0"
              fixed-header
              class="table"
              :footer-props="{
                'items-per-page-options': [100, 200, 500, -1],
              }"
            >
              <template v-slot:[`item.gpDate`]="{ item }">
                <span>{{ formatDate(item.gpDate) }}</span>
              </template>
            </v-data-table>
          </v-card>
        </v-col>
        <!-- <v-card class="card-shadow border-radius-xl">
          <v-card-text class="card-padding" id="scroll_1">
           
          </v-card-text>
        </v-card> -->

        <!-- #endregion -->
      </v-row>
    </v-container>
  </div>
</template>

<script>
import ActionButton from "../../../../components/ActionButton.vue";
import RequisitionAPI from "../../Requisition/api";
import api from "../api";
import { TABLE_HEADERS_LIST_1 } from "../../Requisition/constants";
export default {
  components: { ActionButton },
  name: "BlendSheetDetails",
  data() {
    return {
      blendPlan: null,
      blendPlanDetails: [],
      overlay: false,
      currentPage: {},
      headers: TABLE_HEADERS_LIST_1,
    };
  },
  watch: {
    blendPlan(val) {
      if (!val) {
        this.$router.go(-1);
      }
    },
  },
  methods: {
    formatDate(date) {
      var d = new Date(date),
        month = "" + (d.getMonth() + 1),
        day = "" + d.getDate(),
        year = d.getFullYear();
      if (month.length < 2) month = "0" + month;
      if (day.length < 2) day = "0" + day;
      return [year, month, day].join("-");
    },
    goBack() {
      this.$router.go(-1);
    },
    showSuccessAlert(message) {
      this.$swal({
        title: "Success",
        text: message,
        showConfirmButton: false,
        type: "success",
        timer: 2000,
        icon: "success",
      });
    },
    showErrorAlert(message) {
      this.$swal({
        title: "Error",
        text: message,
        showConfirmButton: false,
        type: "error",
        timer: 5000,
        icon: "error",
      });
      this.overlay = false;
    },
    onActionClick(item) {
      if (item === "APPROVE") {
        this.approveBlendPlan();
      } else if (item === "CANCEL") {
        this.cancelBlendPlan();
      }
    },
    async approveBlendPlan() {
      try {
        await api.approveBlendPlan(this.blendPlan.id);
        this.showSuccessAlert("Plan Approved Successfully");
        this.blendPlan.approvalStatus = true;
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async cancelBlendPlan() {
      try {
        await api.cancelBlendPlan(this.blendPlan.id);
        this.showSuccessAlert("Plan Canceled Successfully");
        this.blendPlan.approvalStatus = false;
      } catch (error) {
        this.showErrorAlert(error);
      }
    },
    async downloadPlan() {
      await api.downloadProvisionalPlan(this.blendPlan.id, this.blendPlan.name);
    },
  },
  async mounted() {
    this.blendPlan = this.$route.params.blendPlan;
    this.currentPage = this.$route.params.currentPage;

    this.blendPlanDetails = await RequisitionAPI.getBlendPlanDetail(this.blendPlan.id);

    this.blendPlanDetails.sort((a, b) => {
      if (a.itemName > b.itemName) {
        return 1;
      }
      if (a.itemName < b.itemName) {
        return -1;
      }
      return 0;
    });
  },
};
</script>

<style></style>
